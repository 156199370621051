// 考生提示模板
import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";
// 提示模板
export const getNoticeList = params => {
  return getRequest("/yethan/register/noticeTemplate/listPage", params);
};
// 添加提示模板
export const addNoticeTemplate = params => {
  return postRequest("/yethan/register/noticeTemplate", params);
};
// 修改提示模板
export const editNoticeTemplate = params => {
  return putRequest("/yethan/register/noticeTemplate", params);
};
// 删除提示模板
export function deleteNoticeTemplate(sid) {
  return deleteRequest("/yethan/register/noticeTemplate/"+sid);
}
// 更新提示模板状态
export const updateNoticeTemplateQyzt = params => {
  return patchRequest("/yethan/register/noticeTemplate/updateNoticeTemplateQyzt", params);
};
// 查询提示模板详情
export function getNoticeDetails(mbbm) {
  return getRequest("/yethan/register/noticeTemplate/"+mbbm);
}
// 导出excel
export const exportMouldExcel = params => {
  return downloadRequest("/yethan/register/noticeTemplate/exportExcel", params);
};
// 导出excel
export const exportMouldDbf= params => {
  return downloadRequest("/yethan/register/noticeTemplate/exportDbf", params);
};
// 简单筛选
export const mouldEasyList = params => {
  return getRequest("/yethan/register/noticeTemplate/easyList", params);
};
<style type="text/css" scoped>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
@import "~@/assets/css/ticket-print.css";
html,body{
  font-size: 16px;
}
/deep/ .outerCotent p,
/deep/ #pdf p {
  margin: 0 !important;
  background-color: transparent !important;
}

/deep/#pdf img:not(.userImg) {
  max-width: 100% !important;
}

/deep/ #nobgColor p {
  background-color: transparent !important;
}

.cjd_title {
  margin-top: 20px;
}
</style>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import $ from "jquery";
import { getNoticeDetails } from "@/api/admin/base/mould.js";
import { getZkzInfo } from "@/api/printCard/printCard.js";
import { getTemplateInfo } from "@/api/score/template.js";
import { getZkzDetails, getZkzSubject } from "@/api/printCard/printCardInfo.js";
import { changeUrl } from "@/api/common/public.js";

export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "准考证单样式 ",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "准考证单样式预览",
          active: true,
        },
      ],
      list2: [],
      list3: [],
      list4: [],
      sid: "",
      userSid: "",
      subVals: {},
      form: {},
      isShowZp: true,
      zpdz: "",
      mid: "",
      mbh: "",
      mbmc: "",
      mbmc2: "",
      imgUrl: "",
      ksbmbh: "",
      sfzjh: "",
      userSfzh: "",
      moduleType: 0,
      defaultZpdz: require("@/assets/images/person/person-none.png"),
      listChoose: [],
      examineeData: {},
      sumWidth: 0, //表格宽度总和
      examForm: {
        zkzmc: "",
        zkzzysx: "",
        zkzbzxx: "",
      },
      ruleList: [], //选择的规则模板
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      totalPage: 1,
    };
  },
  methods: {
    //下载准考证单
    printOrPreview(flag) {
      // flag为真是打印
      let moduleType = this.moduleType;
      this.choosePrintPdf(flag, moduleType);
    },
    choosePrintPdf(flag, type) {
      this.ExportSavePdf("准考证", "pdf", type, flag);
    },
    // 渲染创建好的模板
    getTemplateList(sMid) {
      this.list2 = [];
      this.list3 = [];
      this.list4 = [];
      let func;
      let param;
      if (sMid) {
        func = getTemplateInfo;
        param = sMid;
        func(param).then((res1) => {
          if (res1.status) {
            let imgid = res1.data.cjbjwj;

            if (!res1.data.sfxszp) {
              console.log(11);
              this.isShowZp = false;
              this.$nextTick(() => {
                this.hiddenZp();
              });
            } else {
              this.isShowZp = true;
              this.hiddenZp();
              let userZpdz = "bm_" + this.ksbmbh + "_" + this.userSfzh;
              let fileId = "zkz_" + this.sid + "_" + this.userSfzh;
              if (!this.ksbmbh) {
                //获取照片地址
                userZpdz = fileId;
              }
              this.getFileUrl(userZpdz, true).then((res) => {
                if (res.status) {
                  this.zpdz = res.data;
                  if (this.zpdz) {
                    $(".userImg").each((k) => {
                      $(".userImg")[k].src = this.zpdz;
                    });
                  }
                }
              });
            }
            if (res1.data.sfhxbj) {
              this.moduleType = 1;
              this.pageHeight = Math.floor((1103 / 297) * 210);
              $("#pdf").css("width", "1103");
            } else {
              this.moduleType = 0;
            }

            res1.data.printTemplateFieldBeanList.forEach((item) => {
              if (this.examForm.zpysyq != 4) {
                if (item.zdfl == 1) {
                  this.list2.push(item);
                } else if (item.zdfl == 2) {
                  this.list3.push(item);
                } else if (item.zdfl == 3) {
                  this.list4.push(item);
                }
              } else {
                this.list3.push(item);
              }
            });
            this.getTableRate();
            if (imgid) {
              this.getFileUrl(imgid, true).then((res) => {
                if (res.status) {
                  this.imgUrl = res.data;
                  this.toPdfHtml();
                  $(".page span").html(this.totalPage);
                }
              });
            } else {
              //无背景图
              setTimeout(() => {
                this.$nextTick(()=>{
                  this.toPdfHtml();
                  $(".page span").html(this.totalPage);
                })
              }, 300);
            }
            //
            // $("#mians").css("display", "none")
            return;
          }
        });
      }
    },
    getDetail(sid) {
      getZkzInfo(sid).then((res) => {
        if (res.status) {
          this.examForm = res.data;
          this.mbmc2 = res.data.zkzmc;
          let mbbm = res.data.zkzsmh;
          this.ksbmbh = res.data.ksbmbh;

          if (this.mid) {
            this.getTemplateList(this.mid);
          }
          if (this.userSfzh) {
            this.getInfo(this.userSfzh);
            this.getTemplateList(this.mbh);
          }
          if (mbbm) {
            let newmbbm = mbbm.split(",");
            const list = [];
            newmbbm.forEach((v, i) => {
              list[i] = getNoticeDetails(v);
            });
            Promise.all(list).then((values) => {
              values.forEach((k) => {
                let text = k.data.mbnr;
                text = text.replace(/background:white/g, "");
                this.ruleList.push({
                  mbmc: k.data.mbmc,
                  mbnr: text,
                });
              });
              this.$nextTick(() => {
                let urls = [];
                $("#gradePrint")
                  .find("img")
                  .each((x, y) => urls.push(y.src));
                changeUrl(urls).then((res) => {
                  $("#gradePrint")
                    .find("img")
                    .each((x, y) => {
                      if (y.src.startsWith("http")) {
                        y.src = res.data[y.src];
                      }
                    });
                });
              });
            });
            // getNoticeDetails(mbbm).then(res => {
            //   if (res.status) {
            //     this.zkzkssm = res.data.mbnr
            //   }

            // })
          }
        }
      });
    },

    //显示隐藏照片
    hiddenZp() {
      if (this.isShowZp == false) {
        $(".guding-top").css("width", "99%");
        $(".guding-top").removeClass("border-right");
        $(".guding-img").css("display", "none");
        $(".guding-col").addClass("less-width");
      } else {
        $(".guding-img").css("display", "flex");
        $(".guding-top").css("width", "80%");
        $(".guding-col").removeClass("less-width");
      }
    },
    // 准考证记录
    getInfo(param) {
      getZkzSubject({
        sfzjh: param,
        zkzkid: this.sid,
      }).then((res) => {
        if (res.status) {
          var item = res.data || [];
          item.forEach((ele) => {
            let time = ele.ksrq.replace(/年/g, "-");
            time = time.replace(/月/g, "-");
            time = time.replace(/日/g, "");
            let sj = ele.kssj.split("-")[0];
            ele.ksTime = time + " " + sj;
          });
          item = item.sort((x, y) => {
            return (
              new Date(x.ksTime.replace(/-/g, "/")).getTime() -
              new Date(y.ksTime.replace(/-/g, "/")).getTime()
            );
          });
          this.subVals = item;
          this.examineeData = res.data[0];
          let zkzkid = this.sid;
          this.sfzjh = res.data[0].sfzjhEnc;
          // let userZpdz = "bm|" + this.ksbmbh + "|" + this.sfzjh;
          // let fileId = "zkz|" + zkzkid + "|" + this.sfzjh;
          // if (this.ksbmbh) {
          //   //获取照片地址
          //   this.getFileUrl(userZpdz, true).then((res) => {
          //     if (res.status) {
          //       this.zpdz = res.data;
          //     }
          //   });
          // } else {
          //   this.getFileUrl(fileId, true).then((res) => {
          //     if (res.status) {
          //       this.zpdz = res.data;
          //     }
          //   });
          // }
        }
      });
    },
    getTableRate() {
      // 获取表格字段占比
      let sum = 0;
      let arr = this.list4;
      for (var i = 0; i < arr.length; i++) {
        sum += arr[i].zdbl;
      }
      if (sum > 100) {
        this.sumWidth = sum;
      }
      setTimeout(() => {
        this.pageSign();
      }, 400);
    },

    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml() {
      $("#pdf").empty();
      $("#pdf").append(
        "<div class='pdf' id='pdf1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
      );
      $("#pdf1").append(
        "<div class='page' style='height:30px'>" +
          this.totalPage +
          "/<span></span></div>"
      );
      // 考试规则
      $(".gzContent").each((i, item) => {
        // 标题
        $(item)
          .children("p")
          .each((i, item1) => {
            this.addItem(item1, $(item1).outerHeight(true));
          });
        // 内容
        $(item)
          .find(".contentMain")
          .children()
          .each((i, item1) => {
            this.addItem(item1, $(item1).outerHeight(true));
          });
      });
      // 准考证内容
      let zsItem = $(".zsContent")[0];
      this.addItem(zsItem, $(zsItem).outerHeight(true), this.imgUrl);
      // $("#mians").css("display", "none");
    },

    addItem(item, height, imgurl) {
      let paddingHeight = 84 + 30;
      height = Number(height);
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.totalPage += 1;
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#pdf").append(
          "<div class='pdf' id='pdf" +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
        );
        $("#pdf" + this.pdfNum).append(
          "<div class='page' style='height:30px'>" +
            this.totalPage +
            "/<span></span></div>"
        );
      }
      this.pdfHeight += height;
      // 将内容放入div中
      $(item)
        .clone()
        .appendTo($("#pdf" + this.pdfNum));
      // $(".pdf").css("border", "1px #000000 solid")
      // $(".pdf").css("padding", "126px 93px 97px");
      $(".pdf").css("padding", "56px 70px 28px");
      if (imgurl) {
        $(".pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
  },

  mounted() {
    if (this.sid) {
      this.getDetail(this.sid);
    }
  },
  created() {
    this.sid = this.$route.query.sid ? this.$route.query.sid : ""; //准考证库ID
    this.mid = this.$route.query.mid ? this.$route.query.mid : ""; //模板ID
    // this.imgId = this.$route.query.imgId ? this.$route.query.imgId : ""; //背景图ID
    this.userSid = this.$route.query.userSid ? this.$route.query.userSid : ""; //个人信息sid
    this.userSfzh = this.$route.query.userSfzh
      ? this.$route.query.userSfzh
      : "";
    this.mbh = this.$route.query.mbh ? this.$route.query.mbh : ""; //模板号
    this.mbmc = this.$route.query.mbmc ? this.$route.query.mbmc : ""; //模板名称

  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div
      class="card"
      style="margin: 0 auto"
      :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
    >
      <div class="card-body" style="padding: 0">
        <div
          id="pdf"
          style="margin: 0 auto"
          :style="{ width: moduleType == 1 ? '1103px' : '780px' }"
        ></div>
        <div
          class="outerCotent mt-3"
          style="width: 780px; margin: auto; position: relative"
        >
          <!-- <div v-for="item in pageNum" :key="item" :style="{
              position: 'absolute',
              top: item * (pageHeight - 20) + 'px',
              right: '-136px',
              color: 'red',
            }">
            ---第{{ item }}页结束---
          </div> -->
          <div class="style-set-box h-100">
            <el-row id="gradePrint"  class="forbiddenText">
              <el-col
                :span="24"
                ref="main"
                id="mians"
                class="style-right h-100"
                :style="{ backgroundImage: 'url(' + imgUrl + ')' }"
              >
                <div class="w100">
                  <div
                    class="gzContent"
                    id="nobgColor"
                    v-for="(item, i) in ruleList"
                    :key="i"
                  >
                    <!-- <p class="text-center text-dark pb-2" style="font-weight: 600; font-size: 16px">
                      {{ item.mbmc }}
                    </p> -->
                    <div
                      class="contentMain"
                      v-html="item.mbnr"
                      style="font-family:微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"
                    ></div>
                  </div>
                </div>
                <div class="zsContent">
                  <div
                    class="w100 cjd_title mb-2"
                    style="
                      white-space: pre-wrap;
                      font-weight: 600;
                      font-size: 18px;
                    "
                  >
                    {{ mbmc ? mbmc : mbmc2 }}
                  </div>

                  <div class="exam-form">
                    <el-row style="" class="border-diff top-height">
                      <el-col class="guding-top">
                        <div
                          ref="list1Container"
                          class="flexList guding-col"
                          :list="list2"
                          group="people"
                          style="min-height: 40px"
                        >
                          <div
                            class="flexList msgLabel"
                            :class="[
                              'w' + element.zdbl,
                              element.txlx == 'textarea' ? 'msgtextarea' : '',
                            ]"
                            v-for="element in list2"
                            :key="element.sid"
                          >
                            <div class="style-name">
                              {{
                                element.zdymc ? element.zdymc : element.zdmc
                              }}：
                            </div>
                            <div class="style-val flexList">
                              {{ examineeData[element.zddm] }}
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col class="guding-img">
                        <!-- <img :src="zpdz?zpdz:defaultZpdz" alt="" style="width: 120px; height: 150px" /> -->
                        <img
                          class="userImg"
                          :src="'data:image/jpeg;base64' +zpdz ? zpdz : defaultZpdz"
                          alt=""
                          style="width: 96px; height: 120px"
                        />
                      </el-col>
                      <el-col>
                        <div
                          style="width: 99%; flex-wrap: wrap"
                          class="flexList fs-xs less-width"
                          :list="list3"
                          group="people"
                        >
                          <div
                            class="flexList msgLabel"
                            :class="[
                              'w' + element.zdbl,
                              element.txlx == 'textarea' ? 'msgtextarea' : '',
                            ]"
                            v-for="element in list3"
                            :key="element.sid"
                          >
                            <div class="style-name">
                              {{
                                element.zdymc ? element.zdymc : element.zdmc
                              }}：
                            </div>
                            <div class="style-val flexList">
                              {{ examineeData[element.zddm] }}
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>

                    <el-row>
                      <table class="table zkzTable mt-3">
                        <thead>
                          <th
                            v-for="obj in list4"
                            :key="obj.sid"
                            :style="{
                              width:
                                (sumWidth && sumWidth != 0
                                  ? (obj.zdbl / sumWidth).toFixed(3) * 100
                                  : obj.zdbl) + '%',
                            }"
                          >
                            {{ obj.zdymc ? obj.zdymc : obj.zdmc }}
                          </th>
                        </thead>
                        <tbody v-if="subVals.length">
                          <tr v-for="(value, index) in subVals" :key="index">
                            <td v-for="obj in list4" :key="obj.sid">
                              {{ value[obj.zddm] }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </el-row>
                  </div>
                  <div class="w100 mb-3">
                    <!-- <div class="text-dark font-size-12" style="white-space: pre-wrap">
                      {{ examForm.zkzzysx }}
                    </div> -->
                    <div v-html="examForm.zkzzysx"></div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="mt-3 flexList">
          <!-- <button type="button" class="btn btn-info h30 mr-2" @click="printOrPreview(false)">
            打印预览
          </button> -->
          <!-- <button type="button" class="btn btn-info h30 mr-2" v-print="'#gradePrint'">打印预览</button> -->
          <button
            type="button"
            class="btn btn-info flexList h30"
            @click="printOrPreview(true)"
          >
            <img
              src="@/assets/images/person/file-pdf.png"
              class="mr-2"
            />下载电子准考证
          </button>
        </div>
      </div>
    </div>

    <!-- 弹窗开始 -->

    <!-- 弹窗结束-->
  </Layout>
</template>
